import React, { useCallback, useEffect, useState } from 'react'
import bepassCopyright from './images/logo_bepass_cinza.png'
import clientLogo from './images/logo_tm.png'
import batfair from './images/logo_pe_na_areia.png'
import './App.sass'

import { BASE } from './basedata/constants'

import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'
import SuccessHandler from './Biometric/StatesComponents/SuccessHandler'
import { Card } from 'react-bootstrap'

function App() {
  const [userState, setuserState] = useState<string>('')

  const [userToken, setuserToken] = useState<any>('')

  const handleResponse = useCallback((message: any) => {
    setuserState(message.step)
    if (message.token) {
      setuserToken(message.token)
    }
  }, [])

  const verifyBiometry = (token: any) => {

    if (token.substring(0, 4) === "xip7") {
      window.location.href = `https://maracana.bepass.com.br/?token=${token.split('xip7')[1]}`
    }

    if (token.substring(0, 4) === "xip8") {
      window.location.href = `https://maracana.bepass.com.br/?token=${token.split('xip8')[1]}`
    }

    fetch(`${BASE.api.base_url}${BASE.api.validate_face}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token,
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        "token": token
      })
    })
      .then((resg) => resg.json())
      .then((response) => {



        if (response.biometry) {

          if (response.callbackUrl) {

            const redirect = window.sessionStorage.setItem('callbackurl', `${response.callbackUrl}`)

          }
          setuserState('facesuccess')


        } else {
          setuserState('faceinformation')
        }


      })
      .catch((error) => {
        return false
      })
  }


  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    const generatedToken = window.sessionStorage.getItem('token')
    if (params.get('token')) {
      const currentToken = params.get('token'),
        currentCompany = params.get('orc')
      setuserToken(params.get('token'))
      window.sessionStorage.setItem('token', currentToken + '')
      window.sessionStorage.setItem('originCompany', currentCompany + '')

      return verifyBiometry(currentToken)
      // setuserState('faceinformation')
    }

    else if (generatedToken) {
      const currentToken = generatedToken
      setuserToken(generatedToken)
      return verifyBiometry(generatedToken)
      // setuserState('faceinformation')
    } else {


      if (window.location.pathname.length > 2) {

        const urlparam = window.location.pathname.replace('/', '')
        const uuid = `${urlparam.substring(0, 8)}-${urlparam.substring(8, 12)}-${urlparam.substring(12, 16)}-${urlparam.substring(16, 20)}-${urlparam.substring(20)}`;

        fetch(`${BASE.api.base_url}${BASE.api.user_create}/${uuid}`, {
          method: 'GET',
          headers: {
            'x-api-key': BASE.api.api_key
          }
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.token) {
              const currentToken = response.token,
                currentCompany = response.originCompanyId
              setuserToken(response.token)
              window.sessionStorage.setItem('token', currentToken + '')
              window.sessionStorage.setItem('originCompany', currentCompany + '')

              return verifyBiometry(currentToken)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        setuserState('signup')
      }

    }


  }, [])
  return (
    <>
      <div className="Container">
        <div className="logos">
          <img src={clientLogo} className="logo" alt="BePass" />
        </div>

        {userState === 'faceinformation' ? (
          <FaceInstructions onResponse={handleResponse} />
        ) : userState === 'facelogin' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'faceproceed' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'signup' ? (
          <UserSignup onResponse={handleResponse} />
        ) : userState === 'facesuccess' ? (
          <Card>
            <SuccessHandler />
          </Card>
        ) : userState === 'welcome' ? (
          <Welcome onResponse={handleResponse} />
        ) : <></>}
      </div>
      <p className="copyright">
        powered by{' '}
        <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
          <img src={bepassCopyright} className="logo-bepass" alt="bepass" />
        </a>
      </p>
    </>
  )
}

export default App
